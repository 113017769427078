.info_bar_controller_container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0px 10px;
  height: 100%;
}
.info_bar_controller_container .icon_container {
  display: flex;
  align-items: flex-end;
  justify-content: space-around;
  width: 33%;
}
.info_bar_controller_container .icon-title-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  opacity: 0.5;
}
.info_bar_controller_container .icon-title-container:hover {
  opacity: 1;
}
.info_bar_controller_container .icon-title-container.selected {
  opacity: 1;
}
.info_bar_controller_container .icon-title-container .info_controlbar_icon {
  width: 42px;
  cursor: pointer;
}
.info_bar_controller_container .icon-title-container .icon_label {
  width: fit-content;
  color: white;
  text-transform: uppercase;
  font-size: 12px;
}
.info_bar_controller_container .date_range_container {
  color: white;
  text-transform: uppercase;
  display: flex;
  justify-content: space-between;
  width: 33%;
}
.info_bar_controller_container .date_range_container span {
  width: 30px;
  height: 20px;
  font-size: smaller;
  text-align: center;
  font-weight: bold;
  cursor: pointer;
  margin: 0px 2px;
  opacity: 0.5;
}
.info_bar_controller_container .date_range_container span:hover {
  opacity: 1;
}
.info_bar_controller_container .date_range_container .range_selected {
  border-bottom: 2px solid white;
  opacity: 1;
}
.info_bar_controller_container .MuiFormControl-root.MuiTextField-root.MuiFormControl-marginNormal {
  width: 50%;
  margin: 0;
}
.info_bar_controller_container .calculate-button {
  color: white;
  text-transform: uppercase;
  border: medium white solid;
  cursor: pointer;
  font-weight: bold;
  width: 10%;
  height: 35px;
  padding: 10px 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}
.info_bar_controller_container .calculate-button:hover {
  opacity: 0.5;
}
.info_bar_controller_container .MuiInputBase-input {
  color: white;
}
.info_bar_controller_container .MuiFormLabel-root {
  color: rgba(255, 255, 255, 0.678);
}
.info_bar_controller_container .MuiFormLabel-root.Mui-focused {
  color: rgba(255, 255, 255, 0.678);
}
.info_bar_controller_container .MuiIconButton-root {
  color: white;
}
.info_bar_controller_container .MuiInput-underline:after {
  border-bottom: 2px solid white;
  transform: none;
}
.info_bar_controller_container .MuiInput-underline:before {
  border-bottom: 2px solid white;
}