div#create_portfolio_popup {
  height: 400px;
  width: 550px;
  float: right;
  margin-right: 0px;
  position: absolute;
  top: 75px;
  right: 0px;
  z-index: 10;
}
div#create_portfolio_popup .close_popup {
  position: absolute;
  cursor: pointer;
  color: white;
  outline: 2px solid white;
  right: 20px;
  top: 20px;
}
div#create_portfolio_popup form {
  padding: 40px 100px;
  background-color: black;
  text-align: center;
  color: white;
}
div#create_portfolio_popup form p {
  background-color: inherit;
  color: white;
  font-weight: bold;
  font-size: 30px;
  margin-bottom: 40px;
}
div#create_portfolio_popup form label {
  margin-top: 10px;
  display: block;
  text-align: start;
  font-size: 16px;
}
div#create_portfolio_popup form input,
div#create_portfolio_popup form textarea,
div#create_portfolio_popup form button {
  margin-top: 10px;
}
div#create_portfolio_popup form textarea {
  height: 200px;
  width: 98%;
  resize: none;
}
div#create_portfolio_popup form input {
  width: 95%;
}
div#create_portfolio_popup form button {
  margin-top: 40px;
  text-align: center;
  width: 100%;
  height: 50px;
  background-color: black;
  color: white;
  font-weight: bolder;
  border: thick white solid;
  cursor: pointer;
  font-size: larger;
  letter-spacing: 0.1em;
  line-height: 0px;
}