.line-chart {
  margin: 3% 0;
  width: 100%;
}

.custom-tooltip {
  border: 2px solid black;
  padding: 3%;
  width: 120%;
  background-color: rgba(255, 255, 255, 0.842);
}
.custom-tooltip .tooltip-value {
  display: flex;
  width: 100%;
  justify-content: space-between;
}
.custom-tooltip .tooltip-value h5 {
  text-transform: capitalize;
}

.toggle-container {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-right: 10%;
  margin-top: 1%;
  width: 100%;
}
.toggle-container .switch-label {
  opacity: 0.5;
  font-weight: 800;
}

.scatter-chart {
  margin: 3% auto;
  width: 100%;
}
.scatter-chart .custom-tooltip {
  border: 2px solid black;
  padding: 3%;
  width: 120%;
  background-color: white;
}
.scatter-chart .custom-tooltip .tooltip-value {
  display: flex;
  width: 100%;
  justify-content: space-between;
}
.scatter-chart .custom-tooltip .tooltip-value h5 {
  text-transform: capitalize;
}

.legend-container {
  position: absolute;
  right: 2%;
  width: 200px;
  display: flex;
  flex-direction: column;
  transform: translateY(150px);
}
.legend-container .point-legend {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.legend-container .point-legend svg {
  width: 30px;
}
.legend-container .point-legend span {
  opacity: 0.5;
  font-weight: 700;
  font-size: 0.8rem;
}

.optimizer-space {
  width: 100%;
  height: 100px;
}