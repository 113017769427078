.profile-left {
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: flex-start;
  align-items: baseline;
  padding-left: 10%;
  padding-top: 30%;
  padding-right: 4%;
}
.profile-left .profile-title {
  width: 100%;
  text-transform: uppercase;
  text-align: left;
  padding: 25px 0;
  font-size: 20px;
  font-weight: 500;
}
.profile-left .profile-selection {
  cursor: pointer;
  width: 100%;
  text-transform: uppercase;
  text-align: left;
  padding: 15px 0;
  font-size: 24px;
  font-weight: 500;
}
.profile-left .profile-selection.selected {
  font-size: 28px;
  font-weight: 900;
  border-bottom: 3px solid white;
}
.profile-left .profile-log-out-button {
  cursor: pointer;
  margin-top: 30%;
  text-transform: uppercase;
  font-size: 2em;
  font-weight: 700;
}

.profile-right .profile-general-title {
  width: 85%;
  text-transform: uppercase;
  margin-left: 5%;
  margin-right: 10%;
  border-bottom: 2.5px solid black;
  font-weight: 600;
  font-size: 22px;
  padding: 20px 0;
}
.profile-right .profile-edit-container {
  width: 85%;
  text-transform: uppercase;
  margin-left: 5%;
  margin-right: 10%;
  padding: 5% 0;
  border-bottom: 1px solid rgba(48, 48, 48, 0.644);
}
.profile-right .profile-edit-container .profile-initials {
  height: 200px;
  width: 200px;
  margin: 10px auto;
  background-color: black;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 30px;
  font-weight: 800;
  border-radius: 100%;
}
.profile-right .profile-edit-container .profile-edit-initials {
  cursor: pointer;
  color: #2dc9c6;
  margin: 10px auto;
  text-align: center;
  font-size: 1.2em;
}
.profile-right .profile-name-edit-container {
  width: 85%;
  text-transform: uppercase;
  margin-left: 5%;
  margin-right: 10%;
  padding: 2% 0;
}
.profile-right .profile-name-edit-container .profile-name-edit {
  width: 100%;
  border-bottom: 1px solid rgba(48, 48, 48, 0.644);
  padding: 2% 0;
}
.profile-right .profile-name-edit-container .profile-name-edit h4 {
  text-transform: uppercase;
  font-size: 0.8em;
  padding: 4px 0;
  font-weight: 400;
  color: rgba(48, 48, 48, 0.644);
}
.profile-right .profile-name-edit-container .profile-name-edit input {
  border: none;
  outline: none;
  font-size: 1.2em;
  font-weight: 800;
  color: rgba(77, 77, 77, 0.884);
}
.profile-right .profile-name-edit-container .profile-name-edit input:focus {
  border: none;
  outline: none;
}
.profile-right .profile-name-edit-container .profile-name-edit p {
  text-transform: none;
  font-weight: 800;
  color: rgba(77, 77, 77, 0.884);
}
.profile-right .profile-save-changes {
  width: 85%;
  display: flex;
  justify-content: flex-end;
}
.profile-right .profile-save-changes .profile-save-changes-button {
  border: 1px solid rgba(0, 0, 0, 0.795);
  font-weight: 800;
}