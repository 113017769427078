.add-asset-container {
  display: grid;
  grid-template-columns: 5fr 2.5fr 1fr 1.5fr 2.5fr 1fr 2fr;
  column-gap: 20px;
  align-items: baseline;
  border: none;
  height: 100%;
  width: 95%;
  margin: 0 auto;
  margin-top: 10px;
}
.add-asset-container .add-asset-select-assets {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  background-color: black;
  color: white;
  font-weight: 600;
  font-size: 12px;
  border: none;
  height: 30px;
  text-align: left;
  cursor: pointer;
}
.add-asset-container .add-asset-select-assets img {
  position: relative;
  bottom: -50%;
}
.add-asset-container .add-asset-select-assets .add-asset-search-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;
  width: 100%;
  border-bottom: thin whitesmoke solid;
}
.add-asset-container .add-asset-select-assets .add-asset-search-container p {
  opacity: 0.9;
}
.add-asset-container .add-asset-size-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.add-asset-container .add-asset-size-container span {
  display: block;
  position: absolute;
  color: rgba(255, 255, 255, 0.678);
  text-transform: uppercase;
  font-size: 0.75rem;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 500;
  line-height: 1;
  transform: translate(0, -147.5%);
}
.add-asset-container .add-asset-size-container .add-asset-size {
  background-color: black;
  color: white;
  width: 100%;
  font-weight: 600;
  border: none;
  height: 30px;
  text-align: left;
  border-bottom: thin whitesmoke solid;
}
.add-asset-container .add-asset-notional {
  background-color: black;
  color: white;
  font-weight: 600;
  border: none;
  height: 30px;
  text-transform: uppercase;
  font-size: 11px;
  text-align: center;
  padding-top: 10px;
  opacity: 0.9;
}
.add-asset-container .add-asset-trade-price-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.add-asset-container .add-asset-trade-price-container span {
  display: block;
  position: absolute;
  color: rgba(255, 255, 255, 0.678);
  text-transform: uppercase;
  font-size: 0.75rem;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 500;
  line-height: 1;
  transform: translate(0, -147.5%);
}
.add-asset-container .add-asset-trade-price-container .add-asset-trade-price {
  width: 100%;
  background-color: black;
  color: white;
  font-weight: 600;
  border: none;
  height: 30px;
  text-align: left;
  border-bottom: thin whitesmoke solid;
}
.add-asset-container .add-asset-weight {
  background-color: black;
  color: white;
  font-weight: 600;
  border: none;
  height: 30px;
  text-transform: uppercase;
  font-size: 11px;
  text-align: center;
  padding-top: 10px;
  opacity: 0.9;
  width: 100%;
}
.add-asset-container .add-asset-button {
  text-transform: uppercase;
  font-weight: bold;
  color: white;
  width: 100%;
  height: 35px;
  border: medium white solid;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}
.add-asset-container .add-asset-button:hover {
  opacity: 0.5;
}
.add-asset-container .MuiInputBase-input {
  color: white;
}
.add-asset-container .MuiInputLabel-shrink {
  transform: none;
  transform-origin: none;
  font-size: 0.75rem;
  font-weight: 500;
}
.add-asset-container .MuiFormLabel-root {
  color: rgba(255, 255, 255, 0.678);
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
}
.add-asset-container .MuiFormLabel-root.Mui-focused {
  color: rgba(255, 255, 255, 0.678);
}
.add-asset-container .MuiIconButton-root {
  color: rgb(255, 255, 255);
  padding: 0;
}
.add-asset-container .MuiInput-underline:after {
  border-bottom: thin whitesmoke solid;
  transform: none;
}
.add-asset-container .MuiInput-underline:before {
  border-bottom: thin whitesmoke solid;
}
.add-asset-container .MuiFormControl-root {
  flex-direction: row;
  margin: 5% 0;
}
.add-asset-container .MuiFormHelperText-root.Mui-error {
  text-align: center;
}
.add-asset-container .MuiFormHelperText-root {
  font-size: 0.6rem;
  display: flex;
  align-items: center;
  padding: 2px;
}
.add-asset-container input:focus {
  outline: none;
}
.add-asset-container input::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: white;
  font-size: 12px;
  font-weight: 600;
  opacity: 0.9;
}
.add-asset-container input::-moz-placeholder {
  /* Firefox 19+ */
  color: white;
  font-size: 12px;
  font-weight: 600;
  opacity: 0.9;
}
.add-asset-container input:-ms-input-placeholder {
  /* IE 10+ */
  color: white;
  font-size: 12px;
  font-weight: 600;
  opacity: 0.9;
}
.add-asset-container input:-moz-placeholder {
  /* Firefox 18- */
  color: white;
  font-size: 12px;
  font-weight: 600;
  opacity: 0.9;
}

.toggle-all-assets-container {
  background-color: rgba(219, 219, 219, 0.897);
  width: 100%;
}
.toggle-all-assets-container .asset-entry-visibility-icon {
  color: rgb(163, 163, 163);
  margin-left: 2.5%;
}