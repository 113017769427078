.glossary-left {
  margin-top: 50px;
}
.glossary-left .search-input-container {
  margin: 0 auto;
  margin-top: 50px;
  height: 50px;
  width: 80%;
  color: black;
  background-color: white;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.glossary-left .search-input-container .search-glossary-input {
  height: 100%;
  width: 70%;
  border: none;
  font-size: 24px;
  font-weight: 700;
  padding: 0 5%;
  text-transform: uppercase;
}
.glossary-left .search-input-container .search-glossary-input:focus {
  outline: none;
}
.glossary-left .search-input-container .search-icon {
  height: 50px;
  width: 50px;
}
.glossary-left .search-border-bottom {
  display: block;
  margin: 0 auto;
  width: 80%;
  border-bottom: 3px solid white;
  height: 10px;
}

.glossary-right {
  height: 100%;
}
.glossary-right .definitions {
  width: 95%;
  margin: auto;
  overflow: scroll;
  height: 85%;
}
.glossary-right .definitions .glossary-title {
  text-transform: uppercase;
  font-weight: 700;
  padding: 0.5em 0;
  font-size: 1.5em;
}
.glossary-right .definitions .definitions-one-letter-container {
  border-top: 3px solid black;
  padding: 1em 0;
}
.glossary-right .definitions .definitions-one-letter-container .definition-container {
  padding: 0.5em 0;
  color: #7a7a7a;
  border-bottom: 1px solid #7a7a7a;
}
.glossary-right .definitions .definitions-one-letter-container .definition-container:last-child {
  border-bottom: none;
}
.glossary-right .definitions .definitions-one-letter-container .definition-container .definition-name {
  font-size: 1.2em;
  padding: 0.4em 0;
}