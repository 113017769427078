nav {
  overflow: auto;
  background-color: black;
  padding: 20px;
  height: 81px;
}
nav #logo {
  width: 200px;
  float: left;
}
nav .icon {
  margin-right: 20px;
  width: 32px;
  height: 32px;
  border: none;
  vertical-align: top;
}
nav .icon:hover {
  cursor: pointer;
}
nav div#icon-container {
  display: inline;
  width: 500px;
}
nav div#icon-container div {
  display: inline;
  float: right;
}
nav div#icon-container div#first-inner-icon-container {
  overflow: auto;
  border-right: thin white solid;
  margin-right: 10px;
}
nav button.userIcon {
  width: 2rem;
  height: 2rem;
  color: white;
  font-size: 0.9em;
  padding: 0.1em;
  font-weight: bolder;
  border: medium white solid;
  border-radius: 50%;
  background-color: black;
  margin-top: 2px;
}
nav #help_icon {
  background: url("../../resources/help_outline_white_36dp.svg");
  background-size: contain;
}
nav #settings_icon {
  background: url("../../resources/settings_white_36dp.svg");
  background-size: contain;
}
nav #business_icon {
  background: url("../../resources/business_center_white_36dp.svg");
  background-size: contain;
}
nav #home_icon {
  background: url("../../resources/home_white_36dp.svg");
  background-size: contain;
}
nav #network_icon {
  background: url("../../resources/network_icon.svg");
  background-size: contain;
}
nav #notification_icon {
  background: url("../../resources/notifications_none_white_36dp.svg");
  background-size: contain;
}