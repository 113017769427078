.LoginPage {
  display: flex;
  height: 100vh;
}
.LoginPage .warning {
  color: red;
}
.LoginPage #loginLogo {
  background-color: black;
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: white;
}
.LoginPage #loginLogo #logo {
  width: 70%;
  vertical-align: middle;
}
.LoginPage #loginLogo p {
  text-align: center;
  font-weight: bolder;
  font-size: large;
}
.LoginPage #loginForm {
  width: 50%;
  text-align: start;
}
.LoginPage #loginForm a {
  text-decoration: none;
  color: #3366bb;
}
.LoginPage #loginForm .innerContainer {
  width: 40%;
  min-width: fit-content;
  margin: auto;
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}
.LoginPage #loginForm .innerContainer p {
  margin: 10px 0;
}
.LoginPage #loginForm .loginTitle {
  text-align: start;
  font-size: xx-large;
  font-weight: bold;
}
.LoginPage #loginForm .create-account-link {
  cursor: pointer;
}