.share-right-container {
  border-top: 3px solid black;
  margin: 50px;
  margin-top: 83px;
}
.share-right-container .share-input-container {
  margin: 20px 0;
}
.share-right-container .share-input-container h3 {
  font-size: 22px;
  padding: 10px 0;
  color: #686868;
}
.share-right-container .share-input-container input {
  border: none;
  border-bottom: 1px solid #8f8f8f;
  width: 100%;
  color: #8f8f8f;
  font-size: 18px;
  font-weight: 600;
}
.share-right-container .share-input-container input::placeholder {
  color: #d1d1d1;
}
.share-right-container .share-input-container .date-picker-container * {
  border: none;
}
.share-right-container .share-input-container.username {
  padding-bottom: 100px;
  border-bottom: 3px solid black;
}
.share-right-container .share-input-container.username .share-error-message {
  padding: 20px;
  color: red;
  font-weight: 700;
}
.share-right-container .share-buttons {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: 100px;
}
.share-right-container .share-buttons div {
  cursor: pointer;
  width: 40%;
  height: 34px;
  font-size: 26px;
  font-weight: 700;
  text-transform: uppercase;
  text-align: center;
  color: white;
}
.share-right-container .share-buttons div.submit-share-button {
  background-color: black;
}
.share-right-container .share-buttons div.submit-share-button:hover {
  background-color: #323232;
}
.share-right-container .share-response {
  font-size: 22px;
  margin: 5px;
  font-weight: 400;
}

.share-left-container {
  margin: 50px;
}
.share-left-container .share-title {
  text-transform: uppercase;
  padding: 20px;
  width: 100%;
  border-bottom: 3px solid white;
}
.share-left-container .share-description-container {
  margin-top: 50px;
}
.share-left-container .share-description-container p {
  padding-left: 5%;
  font-size: 22px;
  font-weight: 700;
}