.asset-entry-container .asset-entry {
  display: grid;
  grid-template-columns: 5fr 2.5fr 1fr 1.5fr 2.5fr 1fr 2fr;
  column-gap: 20px;
  height: 100%;
  min-height: 60px;
  width: 95%;
  margin: 0 auto;
  border-top: thin lightgray solid;
  align-items: center;
}
.asset-entry-container .asset-entry:first-child {
  border-top: none;
}
.asset-entry-container .asset-entry .asset-entry-icon-name-container {
  display: grid;
  grid-template-columns: 1.5fr 1fr;
  width: 100%;
  align-items: center;
}
.asset-entry-container .asset-entry .asset-entry-icon-name-container .asset-entry-icons {
  display: grid;
  grid-template-columns: 1fr 1fr 2fr 2.5fr 1.5fr;
  column-gap: 3px;
  align-items: center;
  color: #a3a3a3;
  width: 100%;
}
.asset-entry-container .asset-entry .asset-entry-icon-name-container .asset-entry-icons .asset-entry-visibility-icon {
  cursor: pointer;
}
.asset-entry-container .asset-entry .asset-entry-icon-name-container .asset-entry-icons .asset-entry-drag-icon {
  width: 16px;
  height: 24px;
  cursor: pointer;
  background: url("./../../resources/unfold_more_black_24dp.svg") no-repeat;
}
.asset-entry-container .asset-entry .asset-entry-icon-name-container .asset-entry-icons .asset-entry-LR {
  display: flex;
  width: 50px;
  height: 25px;
  justify-content: space-between;
  align-items: center;
  color: white;
  font-weight: bold;
  background-color: rgba(163, 163, 163, 0.63);
}
.asset-entry-container .asset-entry .asset-entry-icon-name-container .asset-entry-icons .asset-entry-LR span {
  cursor: pointer;
  display: block;
  width: 50%;
  height: 25px;
  padding-top: 3px;
  text-align: center;
}
.asset-entry-container .asset-entry .asset-entry-icon-name-container .asset-entry-icons .asset-entry-LR .LR-selected {
  background-color: rgba(122, 122, 122, 0.699);
}
.asset-entry-container .asset-entry .asset-entry-icon-name-container .asset-entry-icons .letter-id {
  color: white;
  font-weight: bold;
  font-size: large;
  height: 24px;
  margin: 0 5px;
  text-align: end;
  padding: 0px 5px;
}
.asset-entry-container .asset-entry .asset-entry-icon-name-container .asset-entry-icons .asset-entry-expand-icon {
  cursor: pointer;
  height: 24px;
}
.asset-entry-container .asset-entry .asset-entry-icon-name-container .asset-entry-name {
  text-transform: capitalize;
}
.asset-entry-container .asset-entry .asset-entry-size-container {
  display: grid;
  grid-template-columns: 3.5fr 4.5fr 20px 20px;
  align-items: center;
  width: 100%;
  max-width: 100%;
}
.asset-entry-container .asset-entry .asset-entry-size-container .asset-entry-size {
  text-align: left;
  font-size: 14px;
}
.asset-entry-container .asset-entry .asset-entry-size-container .asset-entry-size.edited {
  opacity: 0.6;
}
.asset-entry-container .asset-entry .asset-entry-size-container input {
  width: 100%;
  text-align: center;
  border: none;
  border-bottom: solid 2px #bebebe;
}
.asset-entry-container .asset-entry .asset-entry-size-container input::placeholder {
  opacity: 0.4;
}
.asset-entry-container .asset-entry .asset-entry-size-container .edit-size-icon {
  color: #bebebe;
  height: 20px;
  width: 20px;
  border: 2px solid #bebebe;
  margin: 0;
}
.asset-entry-container .asset-entry .asset-entry-size-container .edit-size-icon.close {
  border-left: none;
}
.asset-entry-container .asset-entry .asset-entry-size-container .edit-size-icon.edited {
  background-color: black;
  color: white;
}
.asset-entry-container .asset-entry .asset-entry-size-container .edit-size-icon:hover {
  opacity: 0.7;
}
.asset-entry-container .asset-entry .asset-entry-notional {
  text-align: center;
  font-size: 14px;
}
.asset-entry-container .asset-entry .asset-entry-trade-price {
  text-align: center;
  font-size: 14px;
}
.asset-entry-container .asset-entry .asset-entry-trade-date {
  text-align: center;
  font-size: 14px;
}
.asset-entry-container .asset-entry .asset-entry-weight {
  text-align: center;
  font-size: 14px;
}
.asset-entry-container .asset-entry .asset-entry-delete-asset {
  display: flex;
  justify-content: flex-end;
  width: 100%;
}
.asset-entry-container .asset-entry .asset-entry-delete-asset .delete-asset-icon {
  position: relative;
  right: 0;
  color: #bebebe;
  border: 2px solid #bebebe;
}
.asset-entry-container .asset-entry .asset-entry-delete-asset .delete-asset-icon:hover {
  opacity: 0.5;
}
.asset-entry-container .trade-history {
  display: grid;
  grid-template-columns: 5fr 2.5fr 1fr 1.5fr 2.5fr 1fr 2fr;
  column-gap: 20px;
  align-items: baseline;
  font-size: 12px;
  color: #bebebe;
  width: 95%;
  margin: 0 auto;
}
.asset-entry-container .trade-history .trade-history-description {
  grid-column: 1/2;
  text-transform: uppercase;
  width: 100%;
  text-align: left;
  margin-left: 60%;
  font-weight: 600;
}
.asset-entry-container .trade-history .trade-history-entries-container {
  grid-column: 2/7;
  width: 100%;
}
.asset-entry-container .trade-history .trade-history-entries-container .trade-history-entries {
  display: grid;
  grid-template-columns: 2.5fr 1fr 1.5fr 2.5fr 1fr;
  column-gap: 20px;
  border-top: thin solid #cecece;
  width: 100%;
  min-height: 40px;
  text-align: center;
  align-items: center;
}
.asset-entry-container .trade-history .trade-history-entries-container .trade-history-entries .size-history {
  text-align: left;
}
.asset-entry-container .trade-history .trade-history-entries-container .trade-history-entries .delete-trade-icon {
  color: #bebebe;
  border: 2px solid #bebebe;
  margin: 0 auto;
}
.asset-entry-container .trade-history .trade-history-entries-container .trade-history-entries .delete-trade-icon:hover {
  opacity: 0.6;
}