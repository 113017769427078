.date-picker-container-scale-share {
  width: 100%;
  height: fit-content;
  border-bottom: 1px solid #8f8f8f;
}
.date-picker-container-scale-share div:first-child {
  width: 100%;
}
.date-picker-container-scale-share div:first-child div {
  padding: 0;
}
.date-picker-container-scale-share * {
  color: #8f8f8f;
  border: none;
  font-weight: 600;
}
.date-picker-container-scale-share input {
  font-size: 18px;
}