.panel-container {
  margin: 76px 30px;
}

.scale-right-container {
  border-top: 3px solid black;
  margin: 50px;
  margin-top: 83px;
}
.scale-right-container .scale-input-container {
  margin: 20px 0;
}
.scale-right-container .scale-input-container h3 {
  font-size: 22px;
  padding: 10px 0;
  color: #686868;
}
.scale-right-container .scale-input-container .scale-input-number {
  border: none;
  border-bottom: 1px solid #8f8f8f;
  width: 100%;
  color: #8f8f8f;
  font-size: 18px;
  font-weight: 600;
}
.scale-right-container .scale-buttons {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 100px;
}
.scale-right-container .scale-buttons div {
  cursor: pointer;
  width: 40%;
  height: 34px;
  font-size: 26px;
  font-weight: 700;
  text-transform: uppercase;
  text-align: center;
  color: white;
}
.scale-right-container .scale-buttons div.reset-scale-button {
  background-color: #afafaf;
}
.scale-right-container .scale-buttons div.reset-scale-button:hover {
  background-color: #c3c3c3;
}
.scale-right-container .scale-buttons div.submit-scale-button {
  background-color: black;
}
.scale-right-container .scale-buttons div.submit-scale-button:hover {
  background-color: #323232;
}

.scale-left-container {
  margin: 50px;
}
.scale-left-container .scale-title {
  text-transform: uppercase;
  padding: 20px;
  width: 100%;
  border-bottom: 3px solid white;
}
.scale-left-container .scale-description-container {
  margin-top: 50px;
}
.scale-left-container .scale-description-container p {
  padding-left: 5%;
  font-size: 22px;
  font-weight: 700;
}