.toolbar-icons {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  height: 35px;
}
.toolbar-icons .toolbar-icon {
  cursor: pointer;
  color: white;
  margin: 4px;
  height: 30px;
}
.toolbar-icons .scale-container {
  cursor: pointer;
  width: 35px;
  height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.toolbar-icons .share-container {
  cursor: pointer;
  border: medium solid white;
  width: 170px;
  height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 30px;
  margin-left: 10px;
}
.toolbar-icons .share-container .toolbar-icon {
  margin: 0;
  margin-right: 5px;
  height: 35px;
}
.toolbar-icons .share-container span {
  display: block;
  width: fit-content;
  color: white;
  font-weight: 700;
}

.toolbar-portfolio {
  color: white;
}
.toolbar-portfolio .portfolio-title-icons {
  display: grid;
  grid-template-columns: 65px 65px 65px auto;
  align-items: center;
}
.toolbar-portfolio .portfolio-title-icons .visibility-icon {
  margin: 10px;
  cursor: pointer;
}
.toolbar-portfolio .portfolio-title-icons #LR {
  margin: 0 5px;
  display: flex;
  width: 50px;
  height: 25px;
  justify-content: space-between;
  align-items: center;
  color: white;
  font-weight: 800;
  background-color: rgba(211, 208, 208, 0.808);
}
.toolbar-portfolio .portfolio-title-icons #LR span {
  display: block;
  width: 50%;
  height: 25px;
  padding-top: 3px;
  text-align: center;
  cursor: pointer;
}
.toolbar-portfolio .portfolio-title-icons #LR .LR-selected {
  background-color: rgba(110, 110, 110, 0.699);
}
.toolbar-portfolio .portfolio-title-icons .portfolio-overview-colorblock {
  width: 65px;
  height: 24px;
  margin: 0 5px;
  background-color: #2dc9c6;
}
.toolbar-portfolio .portfolio-title-icons .title {
  display: flex;
  justify-content: baseline;
  margin: 0 20px;
  align-items: center;
  color: white;
  font-weight: 800;
  font-size: 18px;
  text-transform: uppercase;
  height: 25px;
  width: 300px;
}

.toolbar-portfolio-container {
  height: 100%;
  display: grid;
  grid-template-columns: 3.5fr 8.5fr;
  align-items: center;
  justify-content: space-between;
  width: 98%;
  margin: auto;
}

.portfolio-overview-container {
  height: 280px;
  padding: 30px;
  background-color: rgba(202, 199, 199, 0.897);
  display: grid;
  grid-template-columns: 3fr 3fr 2fr 9fr;
  column-gap: 30px;
}
.portfolio-overview-container .portfolio-investment {
  background-color: black;
  grid-column: 1/2;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.portfolio-overview-container .portfolio-return {
  background-color: black;
  grid-column: 2/3;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.portfolio-overview-container .portfolio-number-title {
  font-weight: 700;
  color: white;
  font-size: 20px;
  padding: 5px;
  text-align: center;
}
.portfolio-overview-container .portfolio-number-description {
  color: rgba(184, 184, 184, 0.87);
  font-size: 16px;
  font-weight: 600;
  text-align: center;
  padding: 5px;
}
.portfolio-overview-container .portfolio-number-value {
  font-weight: 700;
  color: white;
  font-size: 22px;
  padding: 15px;
  text-align: center;
}
.portfolio-overview-container .portfolio-pnl {
  background-color: black;
  grid-column: 3/4;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 20px 0;
}
.portfolio-overview-container .portfolio-pnl .pnl-values-container {
  width: 100%;
}
.portfolio-overview-container .portfolio-pnl .pnl-values-container .pnl-title {
  color: white;
  font-weight: 200;
  font-size: 12px;
  padding: 2px;
  text-align: center;
}
.portfolio-overview-container .portfolio-pnl .pnl-values-container .pnl-value {
  color: white;
  font-weight: 700;
  font-size: 12px;
  padding: 2px;
  text-align: center;
}
.portfolio-overview-container .portfolio-pnl .pnl-seperator {
  height: 1px;
  width: 60%;
  margin: auto;
  background-color: #929292;
}
.portfolio-overview-container .portfolio-dial-container {
  grid-column: 4/5;
  height: 100%;
  width: 100%;
  background-color: black;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: space-around;
}