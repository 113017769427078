.settings-left {
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: flex-start;
  align-items: baseline;
  padding-left: 10%;
  padding-top: 30%;
  padding-right: 4%;
}
.settings-left .settings-title {
  width: 100%;
  text-transform: uppercase;
  text-align: left;
  padding: 25px 0;
  font-size: 20px;
  font-weight: 500;
}
.settings-left .settings-selection {
  cursor: pointer;
  width: 100%;
  text-transform: uppercase;
  text-align: left;
  padding: 15px 0;
  font-size: 24px;
  font-weight: 500;
}
.settings-left .settings-selection.selected {
  font-size: 28px;
  font-weight: 900;
  border-bottom: 3px solid white;
}

.settings-right .settings-general-title {
  width: 85%;
  text-transform: uppercase;
  margin-left: 5%;
  margin-right: 10%;
  border-bottom: 2.5px solid black;
  font-weight: 600;
  font-size: 22px;
  padding: 20px 0;
}
.settings-right .settings-advanced-container {
  width: 85%;
  text-transform: uppercase;
  margin-left: 5%;
  margin-right: 10%;
}
.settings-right .settings-advanced-container h5 {
  padding: 5px 0;
  padding-top: 15px;
  font-weight: 400;
}
.settings-right .settings-advanced-container .settings-rolling-risk-container {
  border-bottom: 1px solid black;
}
.settings-right .settings-advanced-container .settings-rolling-risk-container .rolling-risk-switch {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin: 0.5em 0;
}
.settings-right .settings-advanced-container .settings-rolling-risk-container .rolling-risk-switch span {
  font-weight: 700;
}
.settings-right .settings-advanced-container .settings-histo-container {
  border-bottom: 1px solid black;
}
.settings-right .settings-advanced-container .settings-histo-container .histo-switch {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin: 0.5em 0;
}
.settings-right .settings-advanced-container .settings-histo-container .histo-switch span {
  font-weight: 700;
}
.settings-right .settings-advanced-container .settings-risk-calculation-container {
  border-bottom: 1px solid black;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}
.settings-right .settings-advanced-container .settings-risk-calculation-container h5 {
  width: 100%;
}
.settings-right .settings-advanced-container .settings-risk-calculation-container input {
  margin: 1em 0;
  width: 20%;
  height: 30px;
  font-size: 16px;
  text-align: end;
}
.settings-right .settings-advanced-container .settings-portfolio-optimisation {
  border-bottom: 1px solid black;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}
.settings-right .settings-advanced-container .settings-portfolio-optimisation h5 {
  width: 100%;
}
.settings-right .settings-advanced-container .settings-portfolio-optimisation input {
  margin: 1em 0;
  width: 20%;
  height: 30px;
  font-size: 16px;
  text-align: end;
}
.settings-right .settings-advanced-container .settings-dividend-tax {
  border-bottom: 1px solid black;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}
.settings-right .settings-advanced-container .settings-dividend-tax h5 {
  width: 100%;
}
.settings-right .settings-advanced-container .settings-dividend-tax .input-container {
  margin: 1em 0;
  width: 20%;
  height: 30px;
}
.settings-right .settings-advanced-container .settings-dividend-tax .input-container input {
  width: 100%;
  font-size: 16px;
  text-align: end;
  padding-right: 20px;
}
.settings-right .settings-advanced-container .settings-dividend-tax .input-container input::-webkit-outer-spin-button, .settings-right .settings-advanced-container .settings-dividend-tax .input-container input::-webkit-inner-spin-button {
  -webkit-appearance: none !important;
  margin: 0 !important;
}
.settings-right .settings-advanced-container .settings-dividend-tax .input-container input[type=number] {
  -moz-appearance: textfield !important;
}
.settings-right .settings-advanced-container .settings-dividend-tax .input-container::after {
  content: "%";
  display: flex;
  align-items: center;
  justify-content: center;
  height: 28px;
  width: 20px;
  position: relative;
  top: -30px;
  left: 80%;
  font-weight: 800;
  font-size: 0.8em;
}
.settings-right .settings-advanced-container .settings-submit-button-container {
  display: flex;
  width: 100%;
  justify-content: flex-end;
}
.settings-right .settings-advanced-container .settings-submit-button-container .settings-submit-button {
  margin: 2em;
  border: 1px solid rgba(0, 0, 0, 0.795);
  font-weight: 800;
}
.settings-right .settings-base-currency-container {
  width: 85%;
  text-transform: uppercase;
  margin-left: 5%;
  margin-right: 10%;
}
.settings-right .settings-base-currency-container h5 {
  padding: 5px 0;
  padding-top: 15px;
  font-weight: 400;
}
.settings-right .settings-base-currency-container .settings-base-currency {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  padding: 10px 0;
  margin-bottom: 15px;
  border-bottom: 1px solid black;
}
.settings-right .settings-base-currency-container .settings-base-currency p {
  font-weight: 600;
}
.settings-right .settings-base-currency-container .settings-base-currency p span {
  font-weight: 400;
}
.settings-right .settings-base-currency-container .settings-base-currency-header-container {
  width: 100%;
  display: flex;
  border-bottom: 2px solid black;
  justify-content: flex-start;
  align-items: center;
  padding: 10px 0;
  margin-bottom: 10px;
}
.settings-right .settings-base-currency-container .settings-base-currency-header-container h5 {
  height: 100%;
  padding: 0 10px;
  font-size: 18px;
  font-weight: 600;
}
.settings-right .settings-base-currency-container .settings-base-currency-selection {
  width: 95%;
  margin-left: 5%;
  padding: 5% 0;
  height: 100%;
}
.settings-right .settings-base-currency-container .settings-base-currency-selection .setting-base-currency-option {
  cursor: pointer;
  display: flex;
  align-items: center;
  font-weight: 500;
  min-height: 60px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.897);
}
.settings-right .settings-base-currency-container .settings-base-currency-selection .setting-base-currency-option span {
  font-weight: 400;
  padding: 0 10px;
}
.settings-right .settings-base-currency-container .settings-base-currency-selection .setting-base-currency-option.selected {
  font-weight: 700;
}
.settings-right .settings-display-container {
  width: 85%;
  text-transform: uppercase;
  margin-left: 5%;
  margin-right: 10%;
}
.settings-right .settings-display-container h5 {
  padding: 5px 0;
  padding-top: 15px;
  font-weight: 400;
}
.settings-right .settings-display-container .settings-display {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  padding: 10px 0;
  padding-bottom: 400px;
  border-bottom: 1px solid black;
  font-weight: 600;
}
.settings-right .settings-display-container .settings-display .settings-display-switch {
  padding-bottom: 10px;
  border-bottom: 1.5px solid black;
}