label {
  display: block;
  margin: 0;
}

input[type=checkbox] {
  margin-right: 10px;
  vertical-align: text-top;
}

input[type=submit] {
  margin-top: 10px;
  width: 80%;
  height: 35px;
  background-color: black;
  color: white;
  border: none;
  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.2);
}

input[type=submit]:hover,
button:hover {
  cursor: pointer;
}

input[type=submit]:active,
button:active {
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.3);
  color: #ddd;
}

.signupForm select {
  margin: 6px;
}