.MuiTableContainer-root {
  text-transform: capitalize;
}
.MuiTableContainer-root .MuiTableCell-head {
  text-align: center;
}
.MuiTableContainer-root .MuiTableRow-root {
  background-color: #2dc9c6;
}
.MuiTableContainer-root .MuiTableRow-root.MuiTableRow-head {
  background-color: white;
}
.MuiTableContainer-root th.MuiTableCell-root.MuiTableCell-body {
  background-color: white;
}