.panel-container {
  position: absolute;
  margin: 81px 30px;
  top: 5px;
  left: 0;
  right: 0;
  z-index: 10;
  height: 800px;
  display: flex;
}
.panel-container::before {
  content: "";
  position: fixed;
  z-index: -1;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.596);
}
.panel-container .panel-left {
  width: 50%;
  height: 100%;
  background-color: black;
  color: white;
}
.panel-container .panel-right {
  width: 50%;
  height: 100%;
  background-color: white;
  color: black;
}
.panel-container .panel-right .close-icon-container {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}
.panel-container .panel-right .close-icon-container .panel-icon-close {
  border: 2px solid black;
  height: 25px;
  width: 25px;
  cursor: pointer;
  margin: 10px;
}
.panel-container .panel-right .close-icon-container .panel-icon-close:hover {
  opacity: 0.6;
}