#tuto-background {
  position: absolute;
  top: 0;
  left: -30px;
  bottom: 0;
  height: calc(100% + 50vh);
  width: 100vw;
  background-color: rgba(0, 0, 0, 0.53);
  z-index: 10;
}

.tuto-create-portfolio .dashboard-header {
  position: relative;
  z-index: 1001;
  background-color: rgb(255, 255, 255);
}