.asset-selector {
  position: absolute;
  top: 5px;
  left: 0;
  right: 0;
  z-index: 10;
  height: 700px;
  display: flex;
}
.asset-selector::before {
  content: "";
  position: fixed;
  z-index: -1;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.596);
}
.asset-selector .asset-selector-left {
  width: 40%;
  background-color: black;
  color: white;
}
.asset-selector .asset-selector-left .search-input-container {
  margin: 0 auto;
  margin-top: 50px;
  height: 50px;
  width: 80%;
  color: black;
  background-color: white;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.asset-selector .asset-selector-left .search-input-container .search-asset-input {
  height: 100%;
  width: 70%;
  border: none;
  font-size: 24px;
  font-weight: 700;
  padding: 5%;
  text-transform: uppercase;
}
.asset-selector .asset-selector-left .search-input-container .search-asset-input:focus {
  outline: none;
}
.asset-selector .asset-selector-left .search-input-container .search-icon {
  height: 50px;
  width: 50px;
}
.asset-selector .asset-selector-left .search-border-bottom {
  display: block;
  margin: 0 auto;
  width: 80%;
  border-bottom: 3px solid white;
  height: 10px;
}
.asset-selector .asset-selector-left .asset-types {
  width: 70%;
  margin: 0 auto;
  margin-top: 30px;
  margin-left: 20%;
}
.asset-selector .asset-selector-left .asset-types .asset-type-title {
  font-size: 24px;
  font-weight: normal;
  margin-bottom: 10px;
  padding-bottom: 10px;
  cursor: pointer;
}
.asset-selector .asset-selector-left .asset-types .asset-type-title.selected {
  border-bottom: 3px solid white;
  font-weight: 700;
}
.asset-selector .asset-selector-right {
  width: 60%;
  background-color: white;
  color: black;
}
.asset-selector .asset-selector-right .close-asset-selector-icon-container {
  width: 95%;
  margin: 10px auto;
  display: flex;
  justify-content: flex-end;
}
.asset-selector .asset-selector-right .close-asset-selector-icon-container .asset-selector-icon {
  border: 2px solid black;
  height: 30px;
  width: 30px;
  cursor: pointer;
  margin: 5px;
}
.asset-selector .asset-selector-right .close-asset-selector-icon-container .asset-selector-icon:hover {
  opacity: 0.6;
}
.asset-selector .asset-selector-right .asset-selector-list {
  width: 85%;
  margin: 10px auto;
  height: 70%;
}
.asset-selector .asset-selector-right .asset-selector-list .asset-selector-list-title {
  padding: 15px 0;
  margin: 15px 0;
  font-size: 16px;
  text-transform: uppercase;
  border-bottom: 3px solid black;
}
.asset-selector .asset-selector-right .asset-selector-list .asset-selector-list-container {
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: scroll;
}
.asset-selector .asset-selector-right .asset-selector-list .asset-selector-list-container .asset-selector-list-item {
  padding: 6px 0;
  cursor: pointer;
}
.asset-selector .asset-selector-right .asset-selector-list .asset-selector-list-container .asset-selector-list-item.selected {
  font-weight: 700;
}