.dragDropDiv {
  display: grid;
  grid-template-columns: 37px auto;
  grid-template-rows: 80px auto;
  margin: 20px 0px;
}
.dragDropDiv #side_buttons {
  grid-column: 1/2;
  grid-row: 1/2;
  width: min-content;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  background-color: #ddd;
  padding: 0px 10px 3px 3px;
  border-top-left-radius: 10px 10px;
  border-bottom-left-radius: 10px 10px;
  box-shadow: 2px 2px 2px #e0e0e0;
}
.dragDropDiv #side_buttons #minimize_icon {
  background: url("../../resources/minimize_black_24dp.svg") no-repeat;
}
.dragDropDiv #side_buttons #maximize_icon {
  background: url("../../resources/aspect_ratio_black_24dp.svg") no-repeat;
}
.dragDropDiv #side_buttons #drag_icon {
  background: url("../../resources/unfold_more_black_24dp.svg") no-repeat;
}
.dragDropDiv .icon {
  width: 24px;
  height: 24px;
  border: none;
}
.dragDropDiv .icon:hover {
  cursor: pointer;
}
.dragDropDiv #controls {
  grid-column: 2/3;
  grid-row: 1/2;
  background-color: black;
  height: 100%;
  width: 100%;
  margin: auto 0;
}
.dragDropDiv #bottom {
  grid-column: 2/3;
  grid-row: 2/3;
  width: 100%;
  height: 100%;
  box-shadow: 10;
  box-shadow: -6px 6px 6px -6px #e0e0e0, 6px 6px 6px -6px #e0e0e0, -6px 6px 6px -6px #e0e0e0;
}
.dragDropDiv #bottom .add-asset {
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.dragDropDiv #bottom .add-asset p {
  text-align: center;
  font-weight: 600;
}