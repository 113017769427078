.dashboard-header {
  font-weight: bolder;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #c9c9c9;
  height: 10vh;
  min-height: 60px;
  position: relative;
}
.dashboard-header p {
  text-transform: uppercase;
}
.dashboard-header .main-portfolio {
  display: flex;
  align-items: center;
  height: 100%;
  margin: 0px;
  margin-right: 10px;
  padding: 0px;
  width: fit-content;
  white-space: nowrap;
}
.dashboard-header .main-portfolio img {
  vertical-align: middle;
  margin-right: 10px;
}
.dashboard-header .main-portfolio:hover {
  cursor: pointer;
}
.dashboard-header .main-portfolio.current {
  color: black;
  border-bottom: 2px solid black;
}
.dashboard-header .main-portfolio::after {
  content: "";
  display: block;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  border-bottom: 2px #c9c9c9 solid;
  z-index: -1;
}
.dashboard-header .portfolio-list {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: 100%;
  width: 80%;
}
.dashboard-header .portfolio-list-container {
  display: flex;
  justify-content: flex-start;
  overflow-x: scroll;
  height: 100%;
  margin-right: 10px;
  width: 100%;
}
.dashboard-header .portfolio-item {
  display: flex;
  align-items: center;
  height: 100%;
  margin: 0 2%;
  width: fit-content;
}
.dashboard-header .portfolio-item .tab-input-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.dashboard-header .portfolio-item .tab-input-container p {
  white-space: nowrap;
  margin-right: 5px;
}
.dashboard-header .portfolio-item:hover {
  cursor: pointer;
}
.dashboard-header .portfolio-item.current {
  color: black;
  border-bottom: 2px solid black;
}
.dashboard-header button {
  background-color: black;
  color: whitesmoke;
  width: 20%;
  height: 70%;
  float: right;
  margin-right: 10px;
  border: 2px solid whitesmoke;
  border-radius: none;
  text-align: center;
  font-weight: 900;
  font-size: 16px;
}
.dashboard-header button:hover {
  cursor: pointer;
  opacity: 0.5;
}