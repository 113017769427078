.App main {
  position: relative;
  margin: 0px 30px;
  margin-bottom: 50vh;
}
.App .loading-container-mainpage {
  margin: 20px;
}
.App .loading-container-mainpage .loading-container {
  height: 200px;
}
.App .loading-container-mainpage .loading-text {
  margin-top: 10vh;
  width: 100%;
  font-size: 3rem;
  font-weight: 800;
  text-align: center;
}