.date-picker-container-control-bar {
  display: flex;
  color: white;
  text-transform: uppercase;
  justify-content: space-between;
}
.date-picker-container-control-bar .MuiFormControl-root.MuiTextField-root {
  border-bottom: thin whitesmoke solid;
}
.date-picker-container-control-bar .MuiFormControl-root.MuiTextField-root * {
  border: none;
}
.date-picker-container-control-bar.chart {
  width: 30%;
  margin: 0 20px;
  border-bottom: 1.5px solid white;
}
.date-picker-container-control-bar.chart .MuiFormControl-root.MuiTextField-root .MuiInputLabel-root.MuiInputLabel-formControl.MuiInputLabel-animated.MuiInputLabel-outlined.MuiFormLabel-root.MuiFormLabel-colorPrimary {
  transform: translate(1px, -8px) scale(0.75);
}
.date-picker-container-control-bar.add-asset {
  width: 100%;
}
.date-picker-container-control-bar.add-asset .MuiFormControl-root.MuiTextField-root .MuiInputLabel-root.MuiInputLabel-formControl.MuiInputLabel-animated.MuiInputLabel-outlined.MuiFormLabel-root.MuiFormLabel-colorPrimary {
  transform: translate(1px, -10px) scale(1);
  font-size: 0.75rem;
}
.date-picker-container-control-bar span {
  display: none;
}
.date-picker-container-control-bar .range_selected {
  border-bottom: 2px solid white;
  opacity: 1;
}
.date-picker-container-control-bar > div.chart {
  width: 50%;
}
.date-picker-container-control-bar > div.add-asset {
  width: 100%;
}
.date-picker-container-control-bar > div > div > input {
  padding: 12px 0 4.5px;
}