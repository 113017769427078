.portfolio-viewer-header {
  display: grid;
  grid-template-columns: 2.5fr 1fr 1fr 1fr 1fr 1.5fr 1fr 1fr 1fr 1fr;
  grid-template-rows: 100%;
  color: white;
  align-items: center;
  height: 100%;
  width: 98%;
  margin: auto;
}
.portfolio-viewer-header .portfolio-viewer-header-value {
  grid-column: 3/4;
}
.portfolio-viewer-header span {
  display: block;
  text-align: center;
  text-transform: uppercase;
  font-size: 12px;
}
.portfolio-viewer-header .portfolio-viewer-header-ytd-pnl-container {
  display: grid;
  grid-template-columns: 3fr 2fr;
}
.portfolio-viewer-header .portfolio-viewer-header-ytd-pnl-container .portfolio-viewer-header-ytd-pnl {
  text-align: right;
}

.portfolio-viewer-entry {
  display: grid;
  grid-template-rows: 70px auto;
  grid-template-columns: 100%;
  min-height: 70px;
  height: fit-content;
  align-items: center;
  justify-content: center;
  border-bottom: thin solid lightgray;
  width: 98%;
  margin: auto;
  padding: 0;
}
.portfolio-viewer-entry:last-child {
  border-bottom: none;
}
.portfolio-viewer-entry .portfolio {
  display: grid;
  grid-template-columns: 2.5fr 1fr 1fr 1fr 1fr 1.5fr 1fr 1fr 1fr 1fr;
  height: 100%;
  width: 100%;
  align-items: center;
}
.portfolio-viewer-entry .portfolio .risk {
  font-weight: 700;
}
.portfolio-viewer-entry .portfolio p {
  text-align: center;
  font-size: 10px;
  text-transform: uppercase;
}
.portfolio-viewer-entry .portfolio #name {
  text-align: left;
}
.portfolio-viewer-entry .portfolio .portfolio-viewer-icons {
  display: grid;
  grid-template-columns: 1fr 1fr 2fr 2fr 2fr;
  width: 95%;
  margin: 0 auto;
  color: #a3a3a3;
  height: 100%;
  align-items: center;
}
.portfolio-viewer-entry .portfolio .portfolio-viewer-icons .visibility-icon {
  cursor: pointer;
}
.portfolio-viewer-entry .portfolio .portfolio-viewer-icons .drag-icon {
  grid-column: 2/3;
  cursor: pointer;
  width: 24px;
  height: 24px;
  margin: 0 5px;
  background: url("./../../resources/unfold_more_black_24dp.svg") no-repeat;
}
.portfolio-viewer-entry .portfolio .portfolio-viewer-icons .portfolio-viewer-entry-LR {
  cursor: pointer;
  margin: 0 5px;
  display: flex;
  width: 50px;
  height: 25px;
  justify-content: space-between;
  align-items: center;
  color: white;
  font-weight: bold;
  background-color: rgba(163, 163, 163, 0.63);
}
.portfolio-viewer-entry .portfolio .portfolio-viewer-icons .portfolio-viewer-entry-LR span {
  display: block;
  width: 50%;
  height: 25px;
  padding-top: 3px;
  text-align: center;
  cursor: pointer;
}
.portfolio-viewer-entry .portfolio .portfolio-viewer-icons .portfolio-viewer-entry-LR span.LR-selected {
  background-color: rgba(122, 122, 122, 0.699);
}
.portfolio-viewer-entry .portfolio .portfolio-viewer-icons .letterId {
  color: white;
  font-weight: bold;
  font-size: large;
  width: 100%;
  height: 24px;
  margin: 0 5px;
  text-align: end;
  padding: 0px 5px;
}
.portfolio-viewer-entry .portfolio .portfolio-viewer-icons .portfolio-expand-icon {
  width: 100%;
  min-height: 30px;
  cursor: pointer;
}
.portfolio-viewer-entry .portfolio .ytd-pnl-container {
  display: grid;
  grid-template-columns: 3fr 2fr;
}
.portfolio-viewer-entry .portfolio .ytd-pnl-container #ytd-pnl {
  text-align: right;
}
.portfolio-viewer-entry .portfolio .ytd-pnl-container #ytd-percent {
  font-weight: 900;
}
.portfolio-viewer-entry .portfolio .open-close-portfolio {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #a3a3a3;
  height: 100%;
}
.portfolio-viewer-entry .portfolio .open-close-portfolio .portfolio-delete-button {
  height: 35%;
  border: 2px solid #a3a3a3;
  margin: 0 5px;
  cursor: pointer;
}
.portfolio-viewer-entry .portfolio .open-close-portfolio .portfolio-delete-button:hover {
  opacity: 0.5;
}
.portfolio-viewer-entry .portfolio .open-close-portfolio .portfolio-open-button {
  display: flex;
  align-items: center;
  height: 35%;
  padding: 5px;
  border: 2px solid #a3a3a3;
  text-align: center;
  text-transform: uppercase;
  font-weight: 600;
  color: white;
  background-color: #a3a3a3;
  margin: 0 5px;
  cursor: pointer;
}
.portfolio-viewer-entry .portfolio .open-close-portfolio .portfolio-open-button:hover {
  opacity: 0.5;
}
.portfolio-viewer-entry .portfolio-assets-container {
  border-top: thin solid lightgray;
  display: grid;
  grid-template-columns: 2.5fr 9.5fr;
}
.portfolio-viewer-entry .portfolio-assets-container div {
  grid-column: 2/3;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1.5fr 1fr 1fr 1fr 1fr;
  border-bottom: thin solid lightgray;
  text-transform: capitalize;
  font-weight: 500;
  font-size: 8px;
  text-align: center;
  height: fit-content;
  min-height: 50px;
  align-items: center;
}
.portfolio-viewer-entry .portfolio-assets-container div:last-child {
  border-bottom: none;
}
.portfolio-viewer-entry .portfolio-assets-container div span:first-child {
  text-align: left;
}
.portfolio-viewer-entry .portfolio-assets-container div:first-child {
  text-transform: uppercase;
  color: #a3a3a3;
  font-weight: 700;
}
.portfolio-viewer-entry .portfolio-assets-container div:first-child span {
  border: none;
}

.toggle-all-portfolios-container {
  background-color: rgba(219, 219, 219, 0.897);
  width: 100%;
}
.toggle-all-portfolios-container .portfolio-entry-visibility-icon {
  color: #a3a3a3;
  margin-left: 1.5%;
}