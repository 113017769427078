@charset "UTF-8";
.loading-container-chartview {
  margin: 20px;
}
.loading-container-chartview .loading-container {
  height: 100px;
}
.loading-container-chartview .loading-graph-data {
  text-align: center;
  font-size: 24px;
  font-weight: 800;
  margin: 20px 0;
}

.watermark {
  position: relative;
  margin: 20px auto;
  width: 100%;
}
.watermark::after {
  content: "";
  display: block;
  pointer-events: none;
  width: 90%;
  height: 90%;
  position: absolute;
  top: 0;
  left: 5%;
  right: 5%;
  background-image: url("./../../resources/assettrack_logo_02_black.svg");
  background-size: 100%;
  background-position: center;
  background-repeat: no-repeat;
  opacity: 0.04;
}
.watermark.optimizer::after {
  top: 2.5%;
  left: 10%;
  background-size: 80%;
  background-position: bottom left;
}
.watermark::before {
  content: "© assettrack.ch";
  pointer-events: none;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 97.5%;
  left: 5%;
  font-weight: 700;
  opacity: 0.2;
}