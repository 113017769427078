label {
  display: block;
  padding: 5px 0;
}

#input-checkbox {
  display: flex;
  align-items: center;
}

input {
  height: 2em;
}

input[type=checkbox] {
  margin-right: 10px;
}

input[type=submit] {
  margin-top: 10px;
  width: 80%;
  height: 35px;
  background-color: black;
  color: white;
  border: none;
  font-weight: 700;
  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.2);
}

input[type=submit]:hover,
button:hover {
  cursor: pointer;
}

input[type=submit]:active,
button:active {
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.3);
  color: #ddd;
}