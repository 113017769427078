.risk-table {
  margin-bottom: 20px;
}
.risk-table .risk-table-entry {
  display: grid;
  grid-template-columns: 1.2fr 6fr 4fr 2fr;
  grid-template-rows: auto;
  min-height: 70px;
  height: 100%;
  width: 100%;
  font-size: 10px;
  text-transform: capitalize;
}
.risk-table .risk-table-entry.title {
  padding: 0;
  text-transform: uppercase;
}
.risk-table .risk-table-entry .risk-entry-title-container {
  position: relative;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding-top: 10px;
}
.risk-table .risk-table-entry .risk-entry-title-container .sorting-button-container {
  display: flex;
  flex-direction: column;
  position: absolute;
  bottom: 0px;
  margin: 0px auto;
}
.risk-table .risk-table-entry .risk-entry-title-container .sorting-button-container .sorting-button {
  cursor: pointer;
  font-size: 1.2rem;
}
.risk-table .risk-table-entry .risk-entry-title-container .sorting-button-container .sorting-button:first-child {
  transform: translateY(30%);
}
.risk-table .risk-table-entry .risk-entry-title-container .sorting-button-container .sorting-button:not(:first-child) {
  transform: translateY(-30%);
}
.risk-table .risk-table-entry .risk-entry-title-container .sorting-button-container .sorting-button.selected {
  color: rgba(58, 58, 58, 0.863);
}
.risk-table .risk-table-entry h5 {
  padding: 8px;
}
.risk-table .risk-table-entry .risk-entry-name {
  grid-column: 1/2;
  text-align: left;
  font-size: 14px;
  padding-left: 10px;
  display: flex;
  align-items: center;
  justify-content: baseline;
}
.risk-table .risk-table-entry .risk-entry-name.title {
  background-color: rgba(228, 228, 228, 0.76);
}
.risk-table .risk-table-entry .risk-entry-info {
  grid-column: 2/3;
  display: grid;
  grid-template-columns: 0.5fr repeat(2, 1fr) 1.5fr repeat(5, 1fr);
  align-items: center;
  text-align: center;
  border-bottom: 2px solid rgba(223, 223, 223, 0.637);
}
.risk-table .risk-table-entry .risk-entry-info.title {
  background-color: rgba(228, 228, 228, 0.76);
  color: rgba(150, 150, 150, 0.76);
  font-weight: 600;
  font-size: 12px;
  border-bottom: none;
}
.risk-table .risk-table-entry .risk-entry-info.title .pnl-total-container {
  height: 100%;
}
.risk-table .risk-table-entry .risk-entry-info .pnl-total-container {
  display: grid;
  grid-template-columns: 4fr 1fr;
}
.risk-table .risk-table-entry .risk-entry-info .pnl-total-container .pnl-total-percent {
  color: black;
  font-weight: 900;
}
.risk-table .risk-table-entry .risk-entry-optimize-values {
  grid-column: 3/4;
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  align-items: center;
  text-align: center;
  border-bottom: 2px solid rgba(223, 223, 223, 0.637);
}
.risk-table .risk-table-entry .risk-entry-optimize-values.title {
  background-color: rgba(199, 255, 249, 0.616);
  color: rgba(150, 150, 150, 0.76);
  font-weight: 600;
  font-size: 12px;
  border-bottom: none;
}
.risk-table .risk-table-entry .risk-entry-optimizer {
  grid-column: 4/5;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  align-items: center;
  text-align: center;
  margin: 0 10px;
  border-bottom: 2px solid rgba(223, 223, 223, 0.637);
}
.risk-table .risk-table-entry .risk-entry-optimizer.title {
  background-color: rgba(199, 255, 249, 0.616);
  color: rgba(150, 150, 150, 0.76);
  font-weight: 600;
  font-size: 12px;
  border-bottom: none;
  margin: 0;
}
.risk-table .risk-table-entry .risk-entry-optimizer .calculate-optimize {
  grid-column: 1/3;
  display: flex;
  flex-direction: column;
  height: 100%;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
}
.risk-table .risk-table-entry .risk-entry-optimizer .calculate-optimize svg {
  fill: #424242;
  filter: drop-shadow(0px 3px 5px rgba(0, 0, 0, 0.2));
}
.risk-table .risk-table-entry .risk-entry-optimizer .calculate-optimize svg:hover {
  fill: rgba(99, 99, 99, 0.822);
}
.risk-table .risk-table-entry .risk-entry-optimizer .calculate-optimize .loading-opti {
  height: 40%;
  font-size: 18px;
  font-weight: 700;
  color: #424242;
}
.risk-table .risk-table-entry:last-child > * {
  border-bottom: none;
}